.authloaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 9;
}

.authloaderWrapper .authLoader {
  margin-bottom: 8px;
}

.navactive .MuiButtonBase-root {
  background-color: rgba(175, 150, 150, 0.08);
}

.loginWrapper {
  height: 100vh;
  width: 100%;
  background: url("./Assets/login_bgpr.png") center no-repeat;
  background-size: cover;
}

.loginWrapper .loginCard {
  height: 80vh;
  position: absolute;
  top: 51%;
  left: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  border-radius: 10px;
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.loginWrapper .loginCard .loginFormWrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 200px;
}



.nameInput {
  text-align: center;
  color: #000;
  margin-top: 25px;
}

.otherInputs {
  text-align: center;
  color: #000;
  margin-top: 10px;
}

.closeBtn {
  margin: 20px 20px 20px 4px;
  position: relative;
  left: 357px;
  top: 23px;
}

.profileImg {
  position: relative;
  left: 163px;
  top: 15px;
  border-radius: 100%;
}

.currentPass {
  width: 100%;
  height: 45px;
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid #aca5a5;
  padding-left: 15px;
}

.loginFormWrapperPass {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 200px;
  margin-top: 12px;
}

.submitChangePass {
  position: relative;
  top: 20px;
  right: 210px;
}

.cancelChangePass {
  position: relative;
  right: 100px;
  bottom: 18px;
}

.CategoryWrapper {
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #FFF;
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.selectMerchant {
  padding: 10px 20px;
  margin-top: 25px;
  margin-left: 25px;
}

.startDate {
  padding: 10px 5px;
  margin-top: 25px;
  margin-left: 15px;
}

.endDate {
  padding: 10px 5px;
  margin-top: 25px;
  margin-left: 3px;
}

.settleBtn {
  padding: 10px 58px;
  margin-left: 25px;
}
.modal{
  background-color:white ;
  border-radius: 5px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}




.changeColor{
  color: #000;
  text-align: center;
}

.modalDetails{
 background-color: #fff;
 border-radius: 5px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .CardModalWrapper{
  width: 100%;
  height: auto;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  color:#000
} */

/* details modal css */

.first-section{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0rem;
  gap: 2rem;
  /* border: 1px solid black; */
  width : 98%;
  overflow: hidden;
}

.first-section-left{
  flex-direction: row;
  display: flex;
  align-items: center;
  border: 4px solid whitesmoke;
  border-radius: 7px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  width: 65%;
  gap: 3rem;
  margin: 0 0rem;
}

.first-section-left-left{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* .first-section-left-left div{
  height: 4rem;
  width: 4rem;
  border: 1px solid #fbdada;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .first-section-left-left div img{
  object-fit: cover;
              width: 3rem;
              height: auto;
} */



.first-section-left-right {
  height: 24.5rem;
  width: 38rem;
  display: flex;
  justify-content: flex-start;
    margin-left: 0rem;
  align-items: center;
}

.first-section-left-right img{
  height: 16rem;
  width: 16rem;
  /* border-radius: 1rem; */
  /* object-fit: cover; */
}

.Extra-detail-Container{
  margin-top: 4rem;
  margin-left: 3rem;
  height: 24.5rem;
  width: 34rem;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
 /* border: 1px solid black; */
}

.Extra-detail-Container-productType{
  height: 21rem;
  width: auto;
  /* margin-left: 2rem; */
  margin:4.5rem 0rem 0rem 1rem;
  /* border: 1px solid black; */
}

.Extra-detail-Container-productType h3{
  color: rgb(97, 85, 85);
  font-weight: 400;
  font-size: 1.1rem;
  margin: 2.5rem 1rem;
  /* margin: 0 0 0 0; */
}

.Extra-detail-Container-productType h3 span{
  color: rgb(97, 85, 85);
  font-size: 1.2rem;
  font-weight: 600;
}

/* right section */


.first-section-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 35%;
  border: 2px solid whitesmoke;
  border-radius: 7px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  height: 25rem;
  overflow: hidden;
}

.first-section-right-title {
  flex: none;
  width: 100%;
}

.first-section-right-title h3{
   color: grey;
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem 1rem;
            margin: 0 0 0 0;
}



.first-section-right-price-star {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e7e7e7;
          width: 95%;
          padding: 0 0.5rem 0rem 0.8rem;
}

 .first-section-right-price h3{
   font-weight: 500;
              font-size: 1.1rem;
              color:black;
 }


 .first-section-right-star h3{
   font-weight: 400;
              font-size: 0.8rem;
              color: #6c6c6c;
              margin-left: -1rem;
 }

  .first-section-right-details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 95%;
          padding: 0.8rem 0.5rem 0.5rem 0.8rem;
          border-bottom: 1px solid #e7e7e7;
  }

  .first-section-right-details .grid-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    width: 100%;
    }

    .first-section-right-details .grid-container .item{
      text-align: left;
}

.first-section-right-details .grid-container .item h5{
  font-size: 0.8rem;
  font-weight: 400;
  color: #6c6c6c;
  margin: 0.1rem 0;
}


/* Enquiry  details Modal */

.enquiry-detail-modal{
  /* display: flex;
  align-items: center;
  justify-content: center;  */
  width: 90%;
  margin: auto;
  border: 2px solid whitesmoke;
  border-radius: 7px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
  height: auto;
  padding: 1rem 1rem 1rem 1rem;
}

.enquiry-detail-modal .enquiry-grid-container{
  display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    width: 100%;
}

.enquiry-detail-modal .enquiry-grid-container .enq-item{
  text-align: left;
}

.enquiry-detail-modal .enquiry-grid-container .enq-item h3{
  font-size: 1.2rem;
  font-weight: 500;
  color: #6c6c6c;
  margin: 0.5rem 0;
}









.product-detail-page{
  color:#000;

}





.Title-root-74{
  width: 100%;
    text-align: center;
    margin-bottom: 0px !important;
}
#center-axis-container{
  /* position: absolute; */
  left: 0px;
  top: -100px;
  overflow: visible;
}

.profile{
  
  /* height: auto; */
  display: flex;
 
  
 

}
.Title-root-116{
  width: 100%;
    text-align: center;
    /* margin-bottom: 100px; */
}
.data{
  display: flex;
  justify-content: space-around;
  align-items: center;
  
}


.Accordian :hover {
background-color: rgb(80, 77, 77);
/* width:100%; */
}

.sidebar1{animation-name:animatezoom; 
  animation-duration: 1s}
@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
 
/* .moved {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
} */

.effect {
  animation: moveToLeft ;
  animation-duration: 1.5s;
}
@keyframes moveToLeft {
  0% {
    transform: translate(120px);
    opacity: 0;
  }

  100% {
      transform: translate(0px);
      opacity: 1;
  }
}

.moved{
  animation-name: moveToRight;
  animation-duration: 1s;
}

@keyframes moveToRight {
  0% {
      transform: translate(-120px);
      opacity: 0;
  }

  100% {
      transform: translate(0px);
      opacity: 1;
  }
}


/* products card */

.Product-container{
  width: 100%;
  height: auto;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-bottom: 3rem;
  /* position: relative; */
  /* border: 1px solid white; */
}

.Product-card{
  padding: 1rem 0.5rem;
  /* margin: 1rem 1rem 1.5rem 1rem; */
  background-color: #FFF;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  /* width: 19rem; */
  height: auto;
  position: relative;
  /* border: 1px solid #717171; */
}

.icons-Wrapper{
  position: relative;
  cursor: pointer;
  /* display: initial; */
}

.icons-Wrapper .icon{
  position: absolute;
  top : -0.4rem;
  right : 0.2rem;
  z-index: 11;
  

}

.icons-Wrapper .delete{
  position: absolute;
  top : -0.5rem;
  right: 5rem;
  z-index: 5;

}

.icons-Wrapper .edit{
  position: absolute;
  top : -0.5rem;
  right: 3.5rem;
  z-index: 5; 
}

.icons-Wrapper .detail{
  position: absolute;
  right : 1.8rem;
  top : -0.5rem; 
  z-index: 5;
}

.card-img{
  padding: 1rem;
  border-bottom: 1px solid #ebebeb;
}

.card-img img{
  padding-top: 0.5rem;
  width:100%;
   object-fit: cover;
   user-select: none;
}

.product-title h3{
  font-weight: 400;
  font-size: 1rem;
  color: #4d4d4d;
  margin: 1rem 1rem;
}

.Price-details h3{
  font-weight: 500;
  font-size: 0.9rem;
  color: #2e2e2e;
  margin: 1rem 1rem;  
}

.card-rating h3{
   font-weight: 500;
    font-size: 0.9rem;
    margin: 0rem 1rem;
    color: #000;
}

.MuiAccordion-root.Mui-expanded{
  margin: 5px !important;
}

.MuiListItemIcon-root{
  min-width: 40px!important;
}

.MuiTableCell-sizeSmall {
  padding: 10px 10px 10px 11px !important;
}
.MuiList-padding{
  padding-bottom: 55px!important;
}

.MuiAccordionSummary-root{
  padding: 0px 15px !important
}

.MuiAccordionDetails-root {
  display: flex;
  padding: 8px 0px 16px !important
}

.MuiAccordionDetails-root .MuiList-root{
  padding-left: 20px !important;
}

.MuiButton-contained.Mui-disabled{
  color: white !important;
    box-shadow: none;
    background-color: grey !important;
}


 /* agriculture modal of product */

 .first-section-agri{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0rem;
  gap: 1rem;
  width : 99%;

 }

 .first-section-left-agri{
  flex-direction: row;
  display: flex;
  align-items: center;
  border: 4px solid whitesmoke;
  border-radius: 7px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  width: 34%;
  gap: 1.5rem;
  margin: 0 0rem;
 }

 .first-section-left-left-agri{
  display: flex;
  flex-direction: column;
  gap: 2rem;
 }

 .first-section-left-right-agri{
  height: 19rem;
  width: 38rem;
  display: flex;
  justify-content: flex-start;
    margin-left: 0rem;
  align-items: center;
 }

 .first-section-left-right-agri img{
  height: 16rem;
  width: 13rem;
  border-radius: 10px;
 }

 .first-section-right-agri{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 35%;
  border: 2px solid whitesmoke;
  border-radius: 7px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  height: 19rem;
  overflow: hidden;
 }